import React from "react";
import { Stack, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer

    
      style={{
        padding: "40px 40px", // Adjust padding as needed
        backgroundColor: "#130F40",
      }}
    >
      <Stack justifyContent="center" sx={{ color: "white" }}>
        <Typography variant="body1" textAlign="center">
         🧑‍💻 Copyright © Amity University Patna 2024 🔥
        </Typography>
      </Stack>
    </footer>
  );
};

export default Footer;
