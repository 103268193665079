import React from 'react';
import { Container,  Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PaperSubmissionGuidelines = () => {
  return (
    <Container >
      <Box>
        <Typography variant="h5" gutterBottom>
          NCBIDS-2024: PAPER SUBMISSION GUIDELINES
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Format - A4 size, MS Word only (also in PDF or other form)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Word Limit in between - 3000 to 4000 words" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Font - Times New Roman" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Font Size - Heading 14, Text 12" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Margin - Left 1.5” and Top, Bottom and Right - 1” on all four sides" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Line Spacing - 1" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Abstract will be 150 to 300 words with Keywords - At least four" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          FORMAT OF THE PAPER
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Title - Title should be within 15 to 20 words only." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Definitions - Paper should be clear and concise with proper definitions for specialized terms." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Abbreviations - Any abbreviations used should be written in full at first followed by the abbreviations in alphabetical order." />
          </ListItem>
          <ListItem>
            <ListItemText primary="References - The Harvard system should be used in referencing (Example- Smith, J. (2005a). Dutch Citing Practices. The Hague: Holland Research Foundation..........)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Foot Notes - As required." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Self-Declaration - Author must declare originality of work. The article should not have been published or be submitted for publication elsewhere." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Consent - After sending the paper to our conference you will not be able to withdraw your paper by any means till you are intimated the rejection of your paper by the authority. After receiving the paper it will go for review by the peer review committee." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Decision and Copyright - Editorial Board's decision will be final editing and publishing the paper. The copyright of all accepted papers will be reserved with Amity University Patna." />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          AFTER SUBMISSION OF YOUR ABSTRACT
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Notification of acceptance/rejection of the Extended Abstracts submitted will be sent to you via e-mail." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The conference committee reserves the right to decide on the acceptance and final allocation of the session." />
          </ListItem>
          <ListItem>
            <ListItemText primary="By submitting an Extended Abstract you grant the organizers permission to publish it in hard copy or an electronic format." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The presenter must be registered as conference participant before their papers can be scheduled into the program." />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default PaperSubmissionGuidelines;
