import React from 'react'
import  './Main.css'
import { Box, Paper } from '@mui/material'
import Hero from '../Hero/Hero'
import About from '../about/About'
import Educations from '../Educations/Educations'
import Date from '../dates/Date'
import KeyPerson from '../keyperson/KeyPerson'
import PaperSubmission from '../paper/PaperSubmission'
import Registration from '../registration/Registration'
import Contact from '../contact/Contact'
const Main = () => {
  return (
    <Box >
      <Hero/>
      <About/>
      <Date/>
      <Educations/>
      <KeyPerson/>
      <PaperSubmission/>
      <Registration/>
      <Contact/>
    </Box>
  )
}

export default Main