import React from 'react'
import { Box, Typography, List, ListItem } from "@mui/material";
import ConferenceTracks from './ConferenceTracks';
const About = () => {
  return (
    <Box sx={{ padding: { xs: "0px 40px", sm: "0px 40px" }, }} id="about">
      <div class="sm:flex items-center w-full">

        <div class="sm:w-full ">
          <div class="text">
            <h2 class="my-4 font-bold text-2xl  ">ABOUT  <span class="text-indigo-600">AMITY UNIVERSITY PATNA</span>
            </h2>
            <p class="text-gray-700">
              Amity University Patna is a part of 25-years old, leading education group of India, set up with a vision to contribute to nation-building through education, where currently 150,000+ students are pursuing 300 programmes across campuses in India and abroad.
              Amity University Patna is yet another landmark project by Amity to revolutionize the Indian higher education sector by providing globally benchmarked, research and employment oriented education. Amity University Patna is the youngest addition with a vision to contribute to nation building through education. It presently offers various UG and PG programmes like MBA, MCA, B. Tech , B.B.A, B.C.A, B.Com, B.A (English, Psychology), BJMC etc. located in the heart of the city at Bailey Road Patna, the transit campus is a 15 minutes’ drive from Patna Airport and 25 minutes from Patna Railway Station.
            </p>

            <Box mb={2}>
              <Typography variant="h6" fontWeight="bold">
                Department Vision:
              </Typography>
              <Typography variant="body1">
                To impart quality education empowering Electronics and Communication engineers to meet the Technological and societal needs.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontWeight="bold">
                Department Mission:
              </Typography>
              <List sx={{ pl: 2 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    To facilitate students in acquiring proficiency & providing eminence in Technical education.
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    To imbibe value based education that contributes to the human values, ethics and societal relevance
                  </Typography>
                </ListItem>


                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    To foster culture of innovation, industry and research in developing intellectual professionals and entrepreneurs
                  </Typography>
                </ListItem>
              </List>
            </Box>

          </div>
        </div>




      </div>  <div class="sm:w-full ">
        <div class="text">
          <h2 class="my-4 font-bold text-2xl   "><span class="text-indigo-600">AMITY GROUP OF INSTITUTIONS</span>
          </h2>
          <p class="text-gray-700">
            With an ever increasing number, the expanding network of globally benchmarked institutions which includes 9 Universities, 25 Schools and Pre-Schools and campuses in 10 countries. Amity Group of Institutions is home to over 1,50,000 students pursuing 300 programmes in 60 diverse disciplines, cross preschool to Ph.D. <br /> <br />
            Amity Institute of Information Technology and Amity Business School, Amity University Patna, Bihar is one of the most prestigious and reputed institutes in Patna, Bihar, India and preferred by many of the local as well as the students from all over the country. In fact, considering its growth statistics, it is one of the fastest growing institutes in Patna, Bihar.
          </p>

          <Box mb={2}>
            <Typography variant="h6" fontWeight="bold">
              Mission:
            </Typography>
            <Typography variant="body1">
              To impart quality education empowering Electronics and Communication engineers to meet the Technological and societal needs.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" fontWeight="bold">
              Department Mission:
            </Typography>
            <List sx={{ pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  M1: To provide learner-centric environment through quality education and training.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  M2: To lay the foundation for research by fortifying peers & establishing incubation center.
                </Typography>
              </ListItem>


              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  M3: To develop the overall personality of the students to face the challenges of the real world.
                </Typography>
              </ListItem>
            </List>
          </Box>

        </div>



      </div>  <div class="sm:w-full ">
        <div class="text">
          <h2 class="my-4 uppercase font-bold text-2xl "><span class="text-indigo-600">About the conference</span>
          </h2>
          <p class="text-gray-700">
            In today’s world, ability to store, manage, and analyse large-scale data generated by humans and machines has a critical impact on decision making in every business, scientific discovery, social and environmental challenges. This conference deals with different aspects of data science, efficient data processing, Statistical and operation research modelling, large scale computing in business decision making.<br /> <br />
            Business intelligence (BI) is a technology-driven process for analysing data and presenting actionable information to help executives, managers and other corporate end users make informed business decisions. BI encompasses a wide variety of tools, applications and methodologies that enable organizations to collect data from internal systems and external sources; prepare it for analysis; develop and run queries against that data; and create reports, dashboards and data visualizations to make the analytical results available to corporate decision-makers, as well as operational workers.
          </p>



          <Box>

            <List sx={{ listStyleType: "number", pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  Why BI is important?
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  Types of BI tools
                </Typography>
              </ListItem>


              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  BI trends
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="body1">
                  BI for Big data
                </Typography>
              </ListItem>
            </List>
          </Box>

        </div>
      </div>

      <Typography variant='h4' sx={{ mt: 2, mb: 2, fontWeight: "bold", textAlign: "center" }}>
        Conference Tracks
      </Typography>


      <Box width={"100%"} sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 2 }}>
        <ConferenceTracks />
      </Box>

    </Box>
  )
}

export default About
