import { Grid, Box, Typography, Button } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
const Hero = () => {
  return (
    <>
      <Box
        sx={{
          padding: {
            xs: "0 20px",
            sm: "0 40px",
            md: "0 60px",
          },
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          margin: "auto",
          color: "black",
          position: "relative",
          flexDirection: "column",
          
        }}
        id="home"
      >
        <Grid container>
          {/* left */}
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              padding: { xs: "80px 0", sm: "100px 0px" },
              textAlign: { xs: "center", sm: "center" },
              marginTop:"40px"
            }}
          >


            <Box
              height={{ xs: "auto", sm: "130px" }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "28px", sm: "30px", md: "41px", lg: "46px" },
                  fontWeight: "bold",

                }}
              >
                International Conference on Business Intelligence and <br />
                Data Science <span style={{ color: "#4F46E5" }}> ICBIDS – 2024</span>
              </Typography>
            </Box>



            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "22px", sm: "26px", md: "32px", lg: "36px" },
                  fontWeight: "bold",
                }}
              >
                20th and 25th August
              </Typography>
            </Box>
            <Box sx={{ marginTop: "12px" }}>
              <Button style={{ fontSize: "1rem", borderRadius: "24px", color: "white", padding: "4px 24px", backgroundColor: "#4F46E5" }} >ICIBIDS-BROCHURE <ArrowDownwardIcon className="animate-bounce" /></Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Hero;
