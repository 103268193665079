import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

const Date = () => {

  const dates = [
    { label: 'Deadline for Full Paper submission:', date: '05th July 2024' },
    { label: 'Notification of Acceptance:', date: '10th July 2024' },
    { label: 'Deadline for Registration:', date: '15th July 2024' },
    { label: 'Conference:', date: '18th & 19th July 2024' },
  ];

  return (
    <Box sx={{ padding: { xs: '0px', sm: '80px  40px' }, textAlign: 'center' }} id="dates">
      <Typography variant="h5" style={{ textTransform: "uppercase", fontWeight: "bold",marginBottom:"20px",letterSpacing:".1em" }} color={'#4f46e5'} gutterBottom>
        Important Dates
      </Typography>
      <Grid container spacing={2}>
        {dates.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f5f5f5', color: "black" }}>
              <Typography variant="body1" align="left">
                {item.label}
              </Typography>
              <Typography variant="body1" align="right">
                {item.date}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Typography variant="body2" color="black" sx={{ marginTop: '20px' }}>
        At least one of the authors should register to participate in the conference. The payment mode is mentioned on the conference website. Registration is compulsory for participation in the conference.
      </Typography>
    </Box>
  );
};

export default Date;
