import React from 'react';
import { Container, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Registration = () => {
  return (
    <Container className="registration-page"  sx={{marginTop: "10px",
    }} id="registration">
      <Typography variant="h4" gutterBottom>
        Registration Fee for the Conference
      </Typography>
      <Typography variant="body1" paragraph>
        Each Individual Author and Co-Authors of a research paper need to pay as follows (Accommodation and Transportation Expenditure is the author's own expenditure).
      </Typography>

      <Box className="registration-details" mb={4}>
        <Typography variant="h6" gutterBottom>
          Categories
        </Typography>
        <TableContainer component={Paper}>
        <Table sx={{ '& th, & td': { color: '#333', backgroundColor: '#f0f0f0' } }}>
            <TableHead>
              <TableRow>
              <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>Category</TableCell>
                <TableCell>Amount in Rupees Per Author</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Corporate Professionals, Academician/Faculty member (from India)</TableCell>
                <TableCell>Indian Rs.- 4500/- per Author and Rs. 2000/- for more than one, with a maximum of three authors for a Single research paper.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PhD Scholar / Student (from India)</TableCell>
                <TableCell>Indian Rs.- 2500/- per Author and Rs. 1500/- for more than one, with a maximum of three authors (Students only) in a Single paper.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>UG/PG Student</TableCell>
                <TableCell>Rs. 1500/- per Author</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className="payment-methods" mb={4}>
        <Typography variant="h6" gutterBottom>
          Payment Methods
        </Typography>
        <Typography variant="body1" paragraph>
          Payment can be done through Demand Draft / Cheque, DD, RTGS, or UPI.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Demand Draft / Cheque
        </Typography>
        <Typography variant="body2" paragraph>
          Fees can be paid by Demand draft / Cheque. DD and cheque can be drawn on any Nationalized Bank in the name of _______________________________ Payable at.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          RTGS Details
        </Typography>
        <Typography variant="body2" paragraph>
          [RTGS Details here]
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          UPI Details
        </Typography>
        <Typography variant="body2" paragraph>
          [UPI Details here]
        </Typography>
      </Box>

      <Button variant="contained" color="primary" sx={{marginBottom:"20px",width:"200px",borderRadius:"40px"}}>
        Register Now
      </Button>
    </Container>
  );
};

export default Registration;
