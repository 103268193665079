import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Aos from "aos";
import "aos/dist/aos.css";

const Educations = () => {
  // Initialize Aos on component mount
  useEffect(() => {
    Aos.init();
  });

  const events = [
    {
      day: 'Day 1',
      details: [
        { title: 'INAUGURATIONS' },
        { title: 'Key Speaker: Dr. Sriparna Saha' },
        { title: 'Key Speaker: Dr. A.K. Naiyak' },
        { title: 'LUNCH (1:30-2:30)' },
        { title: 'INFORMATION TECHNOLOGY' },
        { title: 'Technical Session-1', speaker: 'Dr. Rajiv Ghosh (Image Processing), Assistant Professor, NIT Patna' },
        { title: 'Technical Session-2', speaker: 'Dr Sriparna Saha' },
        { title: 'MANAGEMENT' },
        { title: 'Technical Session-1', speaker: 'Dr. Subhash Chandra Pandey (AI & Data Science), Assistant Professor, BIT Mesra Patna' },
        { title: 'Technical Session-2', speaker: 'Dr. Rahul Dev Sah, Assistant Professor, DSPMU, Ranchi' },
      ]
    },
    {
      day: 'Day 2',
      details: [
        { title: 'INFORMATION TECHNOLOGY' },
        { title: 'Technical Session-3', speaker: 'Dr. Jyoti Prakash Singh (NLP), Associate Professor, NIT Patna' },
        { title: 'Technical Session-4', speaker: 'Dr. Kumar Abhishek (Data Science), Assistant Professor, NIT Patna' },
        { title: 'MANAGEMENT' },
        { title: 'Technical Session-3', speaker: 'Prof. Lalit Garg, HCI Cloud Computing, University of Malta' },
        { title: 'Technical Session-4', speaker: 'Dr. Amit Kumar Singh (Data Hiding, Data Compression and Cryptography)' },
        { title: 'LUNCH' },
        { title: '3:00-05:00 Valedictory' },
      ]
    }
  ];

  return (
    <Box sx={{ padding: { xs: "80px 40px", sm: "80px 40px" }, }} id="educations">
      <Stack sx={{ marginBottom: { xs: "40px", md: "50px" } }}>
        {/* Section Title */}
        <Typography
          variant="h5"
          sx={{
            margin: "0 auto",
            textAlign: "center",
            width: "200px",
            borderRadius: "12px",
            borderBottom: "4px solid #484C9C",
            padding: "10px",
            fontWeight: "bold",
            letterSpacing: ".1em",
          }}
          color="primary"
        >
          Event Schedule
        </Typography>
      </Stack>

      <Stack>
        {/* Timeline */}
        <Timeline position="alternate-reverse">
          {events.map((event, index) => (
            <TimelineItem key={index} data-aos="zoom-in">
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                {index < events.length - 1 && (
                  <TimelineConnector
                    sx={{
                      height: "200px",
                      backgroundColor: "orange",
                      borderRadius: "12px",
                      width: "3px",
                    }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#F72798",
                    fontWeight: "bold",
                    letterSpacing: ".1em",
                  }}
                >
                  {event.day}
                </Typography>
                {event.details.map((detail, i) => (
                  <Typography key={i} variant="body1" color="initial">
                    {detail.title}
                    {detail.speaker && (
                      <Typography variant="body2" color="textSecondary">
                        {detail.speaker}
                      </Typography>
                    )}
                  </Typography>
                ))}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Stack>
    </Box>
  );
};

export default Educations;
