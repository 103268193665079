import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Footer, Main, Navbar } from "./components";
const App = () => {
  return (
    <Router>
      <Navbar />
      <Main />
      <Footer />

    </Router>
  );
};

export default App;
