import React from 'react';
import { Container, Grid, Typography, List, ListItem } from '@mui/material';

const tracks = [
  {
    title: 'Track-1: Science & Engineering',
    items: [
      'Computational Techniques & Intelligent Systems',
      'Artificial Intelligence & Machine Learning',
      'Big Data & Storage Technologies',
      'Signal & Image Processing',
      'Computer Communication & Networks',
      'Web Technologies',
      'Information Retrieval',
      'Cloud Computing & Cyber Security',
      'Internet of Things',
      'Block Chain Technology',
      'Robotics & Autonomous Systems'
    ]
  },
  {
    title: 'Track-2: Management & Technology',
    items: [
      'Technology in Education, Research, E-Learning',
      'IT in Banking, Finance & Insurance',
      'Healthcare Informatics & Bioinformatics',
      'IT in Governance & Citizen Services',
      'IT & Project Management',
      'IT in Business Management, E-Commerce, M-Commerce & Supply Chain',
      'Data Science & Analytics Applications'
    ]
  },
  {
    title: 'Track-3: Business Intelligence',
    items: [
      'Business Intelligence',
      'Business Intelligence Governance',
      'Role of Business Intelligence in an organization',
      'Statistics Exploratory Data Analysis',
      'Text Analytics',
      'Web Analytics',
      'Change Detection',
      'Data Analytics',
      'Transaction Processing Vs Analytic Processing',
      'Analytics for Consumer',
      'The Analytics Ecosystem',
      'Data Warehouse Management',
      'Decision Support Systems',
      'Dimensional Modelling',
      'Information Integration',
      'Information Retrieval',
      'Semi-Structured and Unstructured Data',
      'Data Governance'
    ]
  },
  {
    title: 'Track-4: Data Science',
    items: [
      'Data Fusion',
      'Pattern Recognition',
      'Predictive Modelling',
      'Support Vector Machines',
      'Data Science',
      'Data Mining',
      'Deep Learning',
      'Evolutionary Computing and Optimization',
      'Feature Selection',
      'Big Data',
      'Web Analytics',
      'Social Analytics',
      'Fuzzy Computing',
      'Hybrid Methods',
      'Neural Network Applications',
      'Process Mining'
    ]
  }
];

function ConferenceTracks() {
  return (
    
      <Grid container spacing={4} sx={{borderRadius:"20px"}}  >
        {tracks.map((track, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Typography variant="h5" gutterBottom>
              {track.title}
            </Typography>
            <List>
              {track.items.map((item, i) => (
                <ListItem key={i}>{item}</ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
  );
}

export default ConferenceTracks;
