import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

const KeyPerson = () => {
  return (
    <Box sx={{display:"flex",width:"100%",justifyContent:"center",padding:"20px"}}  id="keyPerson">
      <Box sx={{width:{xs:"100%",sm:"80%"}}}
      >
 
      <Typography variant='h5'  sx={{textAlign:"center",marginBottom:"20px",fontWeight:"bold"}}>KEY PERSONS</Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color={"black"}>Chief Patron</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={"black"}>
            Dr. Ashok K. Chauhan, Founder President, Amity Education Group<br/>
            Dr. Atul Chauhan, Chancellor, Amity University
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography color={"black"}>Patron</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography color={"black"}>
          Dr. Vivekanand Pandey, Vice-Chancellor, Amity University, Patna
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography color={"black"}>Mentors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={"black"}>
          Mr. U. Ramachandran, Sr. Vice President, Amity Education Group <br />
          Mr. Gauravh Gupta, Vice President, Amity Education Group
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography color={'black'}>Advisory Committee</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={'black'}>
          Dr. Sushil Kumar, Deputy Examination Controller, Amity University, Patna <br />
          Dr. Navneet Kumar Yadav, Deputy Registrar, Amity University, Patna
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography color={'black'}>Conveners</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={'black'}>
          Dr. Rashmi Shekar, Assistant Director and Associate Professor, AIIT, AUP <br />
          Prof. Prasanna Kumar, Assistant Professor, AIIT, AUP
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography color={'black'}>Co-Conveners</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={'black'}>
          Dr. Naveen Kumar, Associate Professor, AIIT, AUP <br />
          Dr. Avinash Kumar, Assistant Professor, AIIT, AUP
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
         expandIcon={<ExpandMoreIcon sx={{color:"blue"}} />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography color={'black'}>Organizing Committee</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={'black'}>
          Dr. Rashmi Shekar, Assistant Director and Associate Professor, AIIT, AUP <br />
Dr. Naveen Kumar, Associate Professor, AIIT, AUP <br />
Dr. Avinash Kumar, Assistant Professor, AIIT, AUP <br />
Prof. Prasanna Kumar, Assistant Professor, AIIT, AUP <b></b>
Prof. Dheeraj Kumar, Assistant Professor, AIIT, AUP <br />
Prof. Niraj Kumar Rai, Assistant Professor, AIIT, AUP <br />
Prof. Avishek Choudhuri, Assistant Professor, AIIT, AUP <b></b>
Prof. Anu Priya, Assistant Professor, AIIT, AUP br

Prof. Avinah Kumar Singh, Assistant Professor, AIIT, AUP <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Box>
    </Box>
  );
}

export default KeyPerson;
