import React, { useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  Hidden,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import Aos from 'aos'
import 'aos/dist/aos.css'
import { NavHashLink, HashLink } from "react-router-hash-link";
import "./Navbar.css";
const Navbar = () => {
  useEffect(()=>{
    Aos.init({duration:1150});
  })
  // Check if the screen width is smaller than 768px
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  // State to manage the drawer open/close status
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  // Open the drawer
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Close the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // Handle click on a mobile menu item
  const handleDrawerItemClick = () => {
    // Close the drawer when a mobile menu item is clicked
    handleDrawerClose();
  };

  return (
    <div>
      {/* Top navigation stack */}
      <Stack
        backgroundColor="primary.main"
        direction={"row"}
        alignItems={"center"}
        padding={"12px 24px"}
        justifyContent={"space-between"}
        sx={{ position: "fixed", width: "100%", zIndex: "1200" }}
      >
        {/* Brand/logo */}
        <Box>
          <HashLink smooth to="#home">
            <Button
              sx={{ color: "orange", letterSpacing: ".2em", fontWeight: "bold" }}
              size="medium"
            >
             ICBIDS 2k24
            </Button>
          </HashLink>
        </Box>

        {/* Navigation buttons (visible on larger screens) */}
        <Hidden smDown>
          <Box>
            <ButtonGroup>
              <NavHashLink smooth to="#home">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                  Home
                </Button>
              </NavHashLink>

              <NavHashLink smooth to="#about">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                  about
                </Button>
              </NavHashLink>

             

              <NavHashLink smooth to="#dates">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                  Dates
                </Button>
              </NavHashLink>

              <NavHashLink smooth to="#keyPerson">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                Key Person
                </Button>
              </NavHashLink>

              <NavHashLink smooth to="#paper">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                paper
                </Button>
              </NavHashLink>

              <NavHashLink smooth to="#publication">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                 Publication
                </Button>
              </NavHashLink>

              <NavHashLink smooth to="#registration">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                Registration
                </Button>
              </NavHashLink>

              <NavHashLink smooth to="#contact">
                <Button sx={{ color: "#f3e5f5" }} variant="none">
                  Contact
                </Button>
              </NavHashLink>
            </ButtonGroup>
          </Box>

          {/* <Box>
            <ButtonGroup>
              <Link
                to="https://www.linkedin.com/in/ashutosh-kumar-7ba1a6211/"
                target="_blank"
              >
                <IconButton
                  aria-label="linkedin"
                  sx={{ backgroundColor: "#F9CA24", }}
                >
                  <LinkedInIcon style={{ color: "white" }} />
                </IconButton>
              </Link>
              <Link to="mailto:coderashukr321@gmail.com " target="_blank">
                <IconButton
                  aria-label="whatsup"
                  sx={{ backgroundColor: "#F72798", margin: "0 10px" }}
                >
                  <EmailIcon style={{ color: "white" }} />
                </IconButton>
              </Link>
              <Link to="https://github.com/Ashukr321" target="_blank">
                <IconButton
                  aria-label="github"
                  sx={{ backgroundColor: "#F9CA24" }}
                >
                  <GitHubIcon
                    style={{ color: "white" }}
                    className="github_icon"
                  />
                </IconButton>
              </Link>

              <Link to="https://wa.me/+917004246315 " target="_blank">
                <IconButton
                  aria-label="whatsup"
                  sx={{ backgroundColor: "#F72798", margin: "0 10px" }}
                >
                  <WhatsAppIcon style={{ color: "white" }} />
                </IconButton>
              </Link>


              
            </ButtonGroup>
          </Box> */}
        </Hidden>

        {/* Mobile menu button (visible on smaller screens) */}
        {isSmallScreen && (
          <Box>
            <IconButton
              sx={{ color: "white" }}
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* Drawer for mobile navigation */}
            <Drawer
              anchor="left"
              padding="20 30px"
              open={drawerOpen}
              onClose={handleDrawerClose}
            >
              <List sx={{ backgroundColor: "#130f40", color: "#fff",height:"100vh" }}>
                {/* Mobile navigation items */}
                <HashLink smooth to="#" style={{ textDecoration: "none" }}>
                  <ListItem
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      home
                    </Typography>
                  </ListItem>
                </HashLink>

                <HashLink
                  smooth
                  to="#about"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      About
                    </Typography>
                  </ListItem>
                </HashLink>

               

                <HashLink
                  smooth
                  to="#dates"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      Dates
                    </Typography>
                  </ListItem>
                </HashLink>

                <HashLink
                  smooth
                  to="#keyPerson"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      Key Person
                    </Typography>
                  </ListItem>
                </HashLink>

                <HashLink
                  smooth
                  to="#paper"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      paper
                    </Typography>
                  </ListItem>
                </HashLink>

                <HashLink
                  smooth
                  to="#publication"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      Publication
                    </Typography>
                  </ListItem>
                </HashLink>


                <HashLink
                  smooth
                  to="#registration"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      Registration
                    </Typography>
                  </ListItem>
                </HashLink>

                



                <HashLink
                  smooth
                  to="#contact"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    justifyContent="center"
                    onClick={handleDrawerItemClick}
                  >
                    <Typography
                      margin={"0 20px"}
                      variant="overline"
                      color="ternary"
                    >
                      contact
                    </Typography>
                  </ListItem>
                </HashLink>

                


               
                
               

                {/* Add more Link components for other pages */}
              </List>
            </Drawer>
          </Box>
        )}
      </Stack>
    </div>
  );
};

export default Navbar;
