import React from 'react';
import { Container,  Typography, Box, Button } from '@mui/material';
import PaperSubmissionGuidelines from './PaperSubmissionGuidelines';

const PaperSubmission = () => {
  return (
    <>
    <Container  id="paper">
      <Box sx={{ padding: { xs: "0px 10px", sm: "40px 40px" }, }} >
        <Typography variant="h5" gutterBottom sx={{fontWeight:"bold"}}>
          Paper Submission
        </Typography>
        <Typography variant="body1" paragraph>
          We invite papers reporting original research works in the following, but not limited to the broad topics. Research papers are invited from
          Entrepreneurs, Industry Professional and Practitioners, Academicians, Research scholars, Consultants, Faculty members and Students on the above
          sub-themes related to Business Management and Technology.
        </Typography>
        <Typography variant="body1" paragraph>
          The conference will be conducted through award lectures, invited lectures from identified experts and contributory presentations in the given thematic
          areas. Keynote addresses from renowned stalwarts in different areas, research papers on identified themes will be called. All submitted papers will be
          peer reviewed and accepted papers will be published as conference proceedings in professional journals of international repute and in good Journals. Both
          academia and industries will be invited to present papers dealing with state-of-art research and future developments.
        </Typography>
        <Typography variant="body1" paragraph>
          Papers describing advanced methodologies, prototypes, systems, tools and techniques and general survey papers indicating future directions are also
          encouraged. Papers describing original work are invited in any of the areas listed. Accepted papers, presented at the conference by one of the authors, will
          be published in the conference proceedings. Acceptance will be based on quality, relevance and originality. Both full research papers and work-in-progress
          are welcome. There will be both oral and poster session.
        </Typography>
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            Note:
          </Typography>
          <Typography variant="body1" paragraph>
            Best Selected papers will be recommended to highly reputed International Journal with ISSN, UGC Care, Scopus & SCI publication.
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          CASH PRIZE FOR 4 BEST RESEARCH PAPERS in both the tracks ALONG WITH CERTIFICATE OF MERIT WOULD BE AWARDED
        </Typography>
        <Typography variant="body1" paragraph>
          Selected Domain-Specific Quality research articles from the conference will be invited for extension and be re-reviewed by Subject area expert for
          inclusion in any of the following Book/ Proceedings/ Journal with additional cost in reputed UGC-Care, Scopus & SCI Journals.
        </Typography>
      </Box>
    </Container>
    <PaperSubmissionGuidelines/>

    <Box sx={{ marginTop: "12px", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center',marginBottom:"20px" }}>
          <Button
            style={{
              fontSize: "1rem",
              borderRadius: "24px",
              color: "white",
              padding: "4px 24px",
              backgroundColor: "#4F46E5",
            }}
          >
            SUBMIT YOUR PAPER
          </Button>
        </Box>
    
    </>

  );
};

export default PaperSubmission;
